<template>
  <v-container fluid fill-height>
    <!-- TÍTULO  -->
    <v-row class="justify-center">
      <v-col cols="12" md="9" align-self="center">
        <page-top-title>
          <template v-slot:toptitle-icon>mdi-pill</template>
          <template v-slot:toptitle-text>Diseases / Genes</template>
        </page-top-title>
      </v-col>
    </v-row>

    <v-layout child-flex>
      <v-card>
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="diseases"
          :search="search"
          :loading="isLoading"
          loading-text="Loading... Please wait"
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          item-key="en"
          show-expand
          class="elevation-1"
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>OMIM</th>
                      <th>Name</th>
                      <th>Symbol</th>
                      <th>Locus</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="gene in item.kb_genes" :key="gene._id">
                      <td>{{ gene.omim }}</td>
                      <td>{{ gene.main_names.en }}</td>
                      <td>{{ gene.symbol }}</td>
                      <td>{{ gene.locus }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </td>
          </template>
        </v-data-table>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import PageTopTitle from "@/components/mylogy/PageTopTitle.vue";

export default {
  name: "ApdxDiseasesGenes",
  components: {
    PageTopTitle
  },
  data() {
    return {
      expanded: [],
      singleExpand: false,
      search: "",
      isLoading: false,
      headers: [
        {
          text: "Disease",
          align: "start",
          sortable: true,
          value: "en"
        }
      ],
      diseases: []
    };
  },

  computed: {},
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let self = this;
      self.isLoading = true;
      fetch(
        `https://strapi.mylogy.xyz/apendix-epg-diseases?token=zyroivKXeWFPxWf9bMXZBbaKCc9fwCprxuAcqKeEt3xZb8g9`
      ).then(function(response) {
        response.json().then(function(data) {
          self.diseases = data.diseases;
          self.isLoading = false;
        });
      });
    }
  }
};
</script>

<style>
.he-tree .tree-node {
  border: none !important;
}
.he-tree .tree-node b {
  cursor: pointer;
}
</style>
